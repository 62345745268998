import App, { AppContext, AppProps } from 'next/app';
import { useEffect } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

import { CurrencyProvider } from '../client/hooks/CurrencyHook';
import ErrorBoundary from '../client/components/errorBoundary';

import './grid.css';
import './style.css';
import React from 'react';
import { Currency, CurrencyRates, getCurrencyRates } from '../client/client';
import { getUserCurrencyFromRequest } from '../pageUtils';

type CustomAppProps = { currency: Currency; currencyRates: CurrencyRates };

function MyApp({
  Component,
  pageProps,
  currency,
  currencyRates,
}: AppProps & CustomAppProps) {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      smoothscroll.polyfill();
    }
  }, []);

  return (
    <ErrorBoundary>
      <CurrencyProvider currency={currency} currencyRates={currencyRates}>
        <Component {...pageProps} />
      </CurrencyProvider>
    </ErrorBoundary>
  );
}

MyApp.getInitialProps = async (context: AppContext) => {
  const ctx = await App.getInitialProps(context);

  const currency = getUserCurrencyFromRequest(context.ctx.req);
  const currencyRates = await getCurrencyRates(currency).then((data) => {
    return data?.currencyRates;
  });

  return { ...ctx, currency: currency, currencyRates: currencyRates };
};

export default MyApp;
